<template>
  <div>
    <base-header class="pb-6 pb-8 pt-5 pt-md-8 bg-gradient-success">
      <!-- Card stats -->
    </base-header>

    <b-container fluid class="mt--7">
      <b-row>
        <b-col>
          <b-overlay :show="loading">
            <b-card no-body>
              <b-card-header class="border-1">
                <h3 class="mb-0">完了済一覧</h3>
              </b-card-header>
              <b-card-body>
                <b-table
                  :items="tasks"
                  :fields="fields"
                  :current-page="currentPage"
                  :per-page="perPage"
                  stacked="md"
                  show-empty
                  small
                  hover
                >
                  <template #cell(name)="row">
                    {{ row.value }}
                  </template>
                  <template #cell(created_dt)="row">
                    {{ showDatetime(row.value) }}
                  </template>
                  <template #cell(updated_dt)="row">
                    {{ showDatetime(row.value) }}
                  </template>
                  <template #cell(status)="row">
                    <b-badge v-if="row.value==='90'" variant="dark">完了</b-badge>
                  </template>
                  <template #cell(actions)="row">
                    <b-button size="sm" variant="link" :to="{name: 'task-finished-detail', params: {task_id: row.item.id}}">
                      <b-icon icon="search" variant="primary"></b-icon>
                    </b-button>
                  </template>
                </b-table>
              </b-card-body>
              <b-card-footer>
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  size="md"
                ></b-pagination>
              </b-card-footer>
            </b-card>
          </b-overlay>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { ajax, common, config } from '../../utils';
import { helper } from '../common';

export default {
  data() {
    return {
      loading: true,
      tasks: [],
      fields: [
        { key: 'name', sortable: true, label: '名称' },
        { key: 'created_dt', sortable: true, label: '申請日時' },
        { key: 'updated_dt', sortable: true, label: '完了日時' },
        { key: 'status', sortable: true, label: 'ステータス', class: 'text-center' },
        { key: 'actions', label: 'Actions', class: 'text-center' },
      ],
      currentPage: 1,
      perPage: config.rowsPerPage,
    }
  },
  computed: {
    totalRows: function() {
      return this.tasks.length;
    },
  },
  created: function () {
    document.title = common.getPageName(this.$route.name);
    this.getFinishedTaskList();
  },
  methods: {
    getFinishedTaskList: function() {
      const vm = this;
      ajax.fetchGet(config.api.task.finished_list).then(data => {
        vm.tasks = data;
      }).catch(errors => {
        helper.show_errors(errors);
      }).finally(() => {
        vm.loading = false;
      });
    },
    showDatetime: function(d) {
      return common.formatDate(new Date(d), 'YYYY/MM/DD hh:mm')
    },
  },
}
</script>